import React, {useEffect, useState} from 'react';
import Layout from "../components/Layout";
import {Button} from "antd";
import styled from 'styled-components';

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 0;
`;

const TrackingPage = () => {
    const [optedOut, setOptedOut] = useState();

    useEffect(() => {
        const flag = window.localStorage.getItem('tracking_opt_out');
        setOptedOut(!!flag);
    }, [])

    const optIn = () => {
        window.localStorage.removeItem('tracking_opt_out');
        setOptedOut(false);
    }

    const optOut = () => {
        window.localStorage.setItem('tracking_opt_out', 'true');
        setOptedOut(true);
    }

    return (
        <Layout>
            <Container>
                <div>
                    You are currently {optedOut ? 'opted out' : 'opted in'} for tracking
                </div>
                {optedOut ? (
                    <Button onClick={() => optIn()}>Opt in</Button>
                ) : (
                    <Button onClick={() => optOut()}>Opt out</Button>
                )}
            </Container>
        </Layout>
    )
}

export default TrackingPage;